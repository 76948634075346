// @flow
import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { borderBottom, getRemFromPx, border, } from '@haaretz/htz-css-tools';
import { FelaComponent, } from 'react-fela';
import Recaptcha from 'react-google-invisible-recaptcha';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import Button from '../Button/Button'; // eslint-disable-line import/no-named-as-default
import CommentList from './CommentList'; // eslint-disable-line import/no-named-as-default
import CommentForm from './CommentForm';
import IconArrow from '../Icon/icons/IconArrow';
import IconReply from '../Icon/icons/IconReply';
import Like from './Like'; // eslint-disable-line import/no-named-as-default
import H from '../AutoLevels/H';
import Section from '../AutoLevels/Section';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import IconStar from '../IconStar/IconStar';
import IconCheck from '../Icon/icons/IconCheck';
import Tooltip from '../Tooltip/Tooltip';

import decodeCommonHTMLEntities from '../../utils/decodeCommonHTMLEntities';

const { isHDC, } = checkSiteFromConfig();

const wrapperStyle = ({
  theme,
  isSubComment,
  isLastSubComment,
  isHighlighted,
}) => ({
  backgroundColor: theme.color('bg', 'base'),
  extend: [
    {
      condition: isSubComment === false,
      style: {
        ...borderBottom('1px', 2, 'solid', theme.color('comments', 'border')),
        '&:last-child': {
          borderBottomWidth: 0,
        },
      },
    },
    {
      condition: isSubComment && !isLastSubComment,
      style: borderBottom(
        '1px',
        2,
        'solid',
        theme.color('comments', 'subcommentBorder')
      ),
    },
  ],
});

const commentWrapperStyle = {
  outline: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingInlineStart: '1rem',
  paddingInlineEnd: '1rem',
  marginTop: '2rem',
};

const commentNumberContainerStyle = ({
  theme,
  isSubComment,
  isAnchorSubComment,
  isHighlighted,
}) => ({
  width: isSubComment ? '6rem' : '100%',
  color: theme.color('comments', 'number'),
  flexShrink: '0',
  flexGrow: '0',
  extend: [ theme.type(3), ],
  backgroundColor: theme.color(
    'comments',
    isHighlighted || isAnchorSubComment ? 'highlightedCommentBg' : 'bg'
  ),
  paddingRight: '1rem',
});

const commentContainerStyle = ({ theme, isAnchorSubComment, }) => ({
  width: '100%',
  flexShrink: '1',
  flexGrow: '1',
  minWidth: '0',
  display: 'grid',
  gridTemplateColumns: '1fr 10fr',
  ...(isAnchorSubComment
    ? {
      backgroundColor: theme.color('comments', 'highlightedCommentBg'),
      ...border('1px', 2, 'solid', ...theme.color('comments', 'border')),
    }
    : {}),
  extend: [ theme.mq({ until: 's', }, { gridTemplateColumns: '11fr 50fr', }), ],
});

const CommentHeaderContStyle = ({ theme, isSubComment, }) => ({
  width: '100%',
  display: 'flex',
  extend: [ theme.mq({ until: 's', }, { display: 'inline-block', }), ],
});

const reportStates = {
  initial: 'initial',
  pending: 'pending',
  reported: 'reported',
  failed: 'failed',
};

// eslint-disable-next-line react/prop-types
const StyledCommentAuthor = ({ children, ...props }) => (
  <FelaComponent
    style={({ theme, }) => ({
      color: theme.color('comments', 'authorName'),
      padding: '0',
      margin: '0',
      wordBreak: 'break-word',
      display: 'inline',
      ...theme.type(0),
    })}
  >
    {({ className, }) => (
      <H className={className} {...props}>
        {children}
      </H>
    )}
  </FelaComponent>
);

const publishingDateStyle = ({ theme, }) => ({
  color: theme.color('comments', 'date'),
  marginInlineEnd: '1rem',
  marginInlineStart: '1rem',
  extend: [ theme.type(-1, { lines: 4, }), ],
});

const subCommentAuthorStyle = ({ theme, }) => ({
  color: theme.color('comments', 'subcommentAuthor'),
  display: 'inline',
  fontStyle: 'italic',
  wordBreak: 'break-word',
});

// eslint-disable-next-line react/prop-types
const StyledEditorPickTag = ({ children, }) => (
  <FelaComponent
    style={({ theme, }) => ({
      color: theme.color('comments', 'highlightStatus'),
      whiteSpace: 'nowrap',
    })}
    as="span"
  >
    {children}
  </FelaComponent>
);

// eslint-disable-next-line react/prop-types
const StyledCommentText = ({ fade, children, ...props }) => (
  <FelaComponent
    style={({ theme, }) => ({
      color: theme.color('comments', 'text'),
      margin: 0,
      wordBreak: 'break-word',
      ...(fade
        ? {
          overflow: 'hidden',
          maxHeight: '80rem',
        }
        : {}),
    })}
  >
    {({ className, }) => (
      <div className={className} {...props}>
        {children}
      </div>
    )}
  </FelaComponent>
);

// eslint-disable-next-line react/prop-types
const Fade = ({ isHighlighted, children, }) => (
  <FelaComponent
    style={({ theme, }) => ({
      display: 'block',
      height: 0,
      ':after': {
        display: 'block',
        content: '""',
        width: '100%',
        height: '14rem',
        position: 'relative',
        top: '-14rem',
        background: `linear-gradient(rgba(255,255,255, 0), ${theme.color(
          'comments',
          isHighlighted ? 'highlightedCommentBg' : 'bg'
        )})`,
      },
    })}
    as="span"
  >
    {children}
  </FelaComponent>
);

const commentFooterStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'flex-start',
  alignItems: 'center',
  marginTop: '1rem',
};

const likeMiscStyles = {
  padding: '1rem 0',
};
const disLikeMiscStyles = {
  marginInlineStart: '3rem',
  padding: '1rem 0',
};

class Comment extends React.Component {
  commentFormRef = null;

  static propTypes = {
    /** The Comment author */
    author: PropTypes.string.isRequired,
    /** the bps object from the theme */
    bps: PropTypes.shape({ width: PropTypes.object, }).isRequired,
    /** the typeConf object from the theme */
    typeConf: PropTypes.shape({
      default: PropTypes.object,
      xl: PropTypes.object,
    }).isRequired,
    /** The Comment ID */
    commentId: PropTypes.string.isRequired,
    /** The Comment Number */
    commentNumber: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, ])
      .isRequired,
    /**
     * An Object holding the Minus Rates of all the comments in the `CommentsElement`.
     * The Object has an id key for each comment that has at least one Minus vote.
     * The corresponding value is the total number of Minus votes the comment has.
     * The entire object is passed since the comment might have subComments that
     * will need to check their own rate.
     */
    commentsMinusRate: PropTypes.shape({
      id: PropTypes.number,
    }),
    /**
     * An Object holding the Plus Rates of all the comments in the `CommentsElement`.
     * The Object has an id key for each comment that has at least one Plus vote.
     * The corresponding value is the rate of Plus votes the comment has.
     * The entire object is passed since the comment might have subComments that
     * will need to check their own rate.
     */
    commentsPlusRate: PropTypes.shape({
      id: PropTypes.number,
    }),
    /** The comment text */
    commentText: PropTypes.string,
    /**
     * A callback passed on to the reply `<CommentForm />`
     * @param {String} commentAuthor
     *   The new comment author
     * @param {String} commentTextHtml
     *   The new comment text innerHTML
     * @param {String} parentCommentId
     *   The parent CommentId - defaults to '0' if there is no `parentCommentId`
     */
    initNewComment: PropTypes.func,
    /**
     * A callaback that initiates a vote, `<Like />` sends up the commentId and the rate ("plus"/"minus")
     */
    initVote: PropTypes.func.isRequired,
    /**
     * Is this comment marked as an editors pick?
     * Notice this is a string that should be "true" or "false"
     * as a string and not as a boolean (database limitations).
     */
    isEditorPick: PropTypes.string,
    /** passed as true when the SubComment is the first in the `subComments` Array */
    isFirstSubComment: PropTypes.bool,
    /** passed as true when the SubComment is the last in the `subComments` Array */
    isLastSubComment: PropTypes.bool,
    /** True when the comment is a subComment */
    isSubComment: PropTypes.bool,
    /**
     * A callback that causes the parent `<Comment />` to open its reply `<CommentForm />`
     * Replying to comments can only go one level deep so when replying to subComments,
     * we are actually replying to the parent of the subComment
     */
    openParentReplyForm: PropTypes.func,
    /** The author of the parent comment, null if it is not a subComment */
    parentAuthor: PropTypes.string,
    /** The publishing date for display as recieved from data base */
    publishingDateForDisplay: PropTypes.string.isRequired,
    /**
     * A CallBack that sends up the the commentId in order to report the Comment as abusive
     * @param {String} commentId
     * @returns {Promise} A promise fullfilled with an object of shape { reportSuccess: Boolean }
     */
    reportAbuse: PropTypes.func.isRequired,
    /**
     * A Boolean indicating that a comment abuse report may not be
     * proccessed at this time
     */
    reportAbuseDisabled: PropTypes.bool,
    /**
     * A callback that gets the called when submitting the sign up to notification form in `<CommentSent />`
     * @param {String} - notificationEmail - The email the user entered
     */
    signUpNotification: PropTypes.func,
    /**
     * A Array of `<Comment />`'s to be rendered as the subComments to this `<Comment/>`
     */
    subComments: PropTypes.arrayOf(PropTypes.object),
    /**
     * The `<Comment />` title
     */
    title: PropTypes.string,
    subSingleCommentId: PropTypes.string,
    userType: PropTypes.string,
    authorRate: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, ]),
    /**
     * Disables the Reply button
     */
    disableReply: PropTypes.bool,
    /**
     * enable / disable choosing nickname
     */
    enableNickname: PropTypes.bool,
    wrapperRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement), }),
    ]),
    isWebView: PropTypes.bool.isRequired,
    isPageLoaded: PropTypes.bool.isRequired,
    likes: PropTypes.number.isRequired,
    dislikes: PropTypes.number.isRequired,
    isSingleComment: PropTypes.bool,
    subSingleCommentId: PropTypes.string,
  };

  static defaultProps = {
    commentsMinusRate: {},
    commentsPlusRate: {},
    commentText: '',
    initNewComment: undefined,
    isEditorPick: false,
    isFirstSubComment: false,
    isLastSubComment: false,
    isSubComment: false,
    openParentReplyForm: undefined,
    parentAuthor: null,
    signUpNotification: undefined,
    subComments: undefined,
    title: '',
    subSingleCommentId: null,
    authorRate: null,
    userType: null,
    subSingleCommentId: null,
    isSingleComment: null,
    disableReply: false,
    enableNickname: true,
    reportAbuseDisabled: false,
  };

  state = {
    abuseReportState: reportStates.initial,
    displayReplyForm: false,
    fadeText: false,
    // truncateAuthorName: true,
    // can be plus or minus, will disable like buttons and increase like count
    userLike: null,
  };

  constructor(props) {
    super(props);
    this.commentFormRef = React.createRef();
  }

  commentPlusRateDB = Object.prototype.hasOwnProperty.call(
    this.props.commentsPlusRate,
    this.props.commentId
  )
    ? this.props.commentsPlusRate[this.props.commentId]
    : 0;

  commentMinusRateDB = Object.prototype.hasOwnProperty.call(
    this.props.commentsMinusRate,
    this.props.commentId
  )
    ? this.props.commentsMinusRate[this.props.commentId]
    : 0;

  isUsersChoice = this.commentPlusRate - this.commentMinusRate > 10;

  componentDidMount() {
    const height = this.commentTextEl ? this.commentTextEl.clientHeight : 0;
    const remHeight = getRemFromPx(this.props.bps, this.props.typeConf, height);
    if (remHeight > 80) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ fadeText: true, });
    }
  }

  componentDidUpdate = (prevProp, prevState) => {
    if (prevState.fadeText !== this.state.fadeText && !this.state.fadeText) {
      this.commentTextEl.tabIndex = '-1';
      this.commentTextEl.focus();
    }

    if (!prevState.displayReplyForm && this.state.displayReplyForm) {
      this.scrollToCommentForm();
    }
  };

  handleReplyClick = () => {
    this.state.displayReplyForm
      ? this.setState({ displayReplyForm: false, })
      : this.props.isSubComment
        ? this.props.openParentReplyForm()
        : this.setState({ displayReplyForm: true, });
  };

  generateCommentMarkup() {
    return { __html: decodeCommonHTMLEntities(this.props.commentText), };
  }

  scrollToCommentForm() {
    if (this.commentFormRef.current) {
      this.commentFormRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  render() {
    const {
      author,
      commentId,
      commentsMinusRate,
      commentNumber,
      commentsPlusRate,
      isSubComment,
      initNewComment,
      initVote,
      isEditorPick,
      isFirstSubComment,
      isLastSubComment,
      parentAuthor,
      publishingDateForDisplay,
      reportAbuse,
      reportAbuseDisabled,
      signUpNotification,
      subComments,
      title,
      authorRate,
      userType,
      disableReply,
      enableNickname,
      wrapperRef,
      isWebView,
      isPageLoaded,
      likes,
      dislikes,
      isSingleComment,
      subSingleCommentId,
    } = this.props;

    const { abuseReportState, } = this.state;
    const abuseReported = abuseReportState === reportStates.reported;
    const abuseReportPending = abuseReportState === reportStates.pending;
    const abuseReportFailed = abuseReportState === reportStates.failed;

    const isHighlighted = this.props.isEditorPick === true || this.isUsersChoice;
    const commentPlusRateUser = this.state.userLike === 'plus' ? 1 : 0;
    const commentPlusRate = likes + commentPlusRateUser;
    const commentMinusRateUser = this.state.userLike === 'minus' ? 1 : 0;
    const commentMinusRate = dislikes + commentMinusRateUser;

    const isVerifiedUser = authorRate > 99; // this indicates Verified user
    const isGoldUser = authorRate > 80 && authorRate <= 99; // this indicates Gold rating user
    const isAnchorSubComment = commentId === subSingleCommentId;
    const isAnonymousHdc = isHDC && userType === 'anonymous';

    return (
      <FelaComponent
        isSubComment={isSubComment}
        isLastSubComment={isLastSubComment}
        isHighlighted={isHighlighted}
        style={wrapperStyle}
      >
        {({
          className,
          theme: {
            direction,
            commentsStyle,
            commentI18n: {
              tags: { editorsPick, usersPick, },
              buttons: {
                replyBtnTxt,
                readMoreBtnTxt,
                reportAbuseBtnTxt,
                reportReceivedTxt,
                reportErrorTxt,
              },
              ariaTexts: { subCommentTxt, },
              tooltip: { ratedUserText, verifiedUserText, },
            },
          },
        }) => (
          <Section
            tagName="article"
            className={className}
            data-test={!isSubComment ? 'comment' : 'subComment'}
            id={commentId}
          >
            <FelaComponent style={commentWrapperStyle}>
              {({ className, theme, }) => (
                <div ref={wrapperRef} className={className}>
                  <FelaComponent
                    style={commentContainerStyle}
                    isAnchorSubComment={isAnchorSubComment}
                  >
                    <FelaComponent
                      isSubComment={isSubComment}
                      isHighlighted={isHighlighted}
                      isAnchorSubComment={isAnchorSubComment}
                      style={commentNumberContainerStyle}
                    >
                      <span data-test="commentNumber">
                        {isSubComment || isSingleComment ? (
                          isFirstSubComment ? (
                            <Fragment>
                              <VisuallyHidden>{subCommentTxt}</VisuallyHidden>
                              <IconReply
                                color={[ 'comments', 'replyIcon', ]}
                                miscStyles={{
                                  ...(direction === 'ltr'
                                    ? { transform: 'rotateY(180deg)', }
                                    : {}),
                                }}
                              />
                            </Fragment>
                          ) : (
                            ''
                          )
                        ) : (
                          commentNumber
                        )}
                      </span>
                    </FelaComponent>
                    <FelaComponent
                      style={{
                        backgroundColor: theme.color(
                          'comments',
                          isHighlighted || isAnchorSubComment
                            ? 'highlightedCommentBg'
                            : 'bg'
                        ),
                        paddingEnd: isHighlighted ? '2rem' : '0rem',
                      }}
                    >
                      <FelaComponent
                        isSubComment={isSubComment}
                        style={CommentHeaderContStyle}
                      >
                        <StyledCommentAuthor data-test="comment-author">
                          {author}
                        </StyledCommentAuthor>
                        {authorRate > 60 ? (
                          <Tooltip
                            text={
                              isVerifiedUser
                                ? verifiedUserText
                                : ratedUserText
                                  && ratedUserText(authorRate || 'לא זמין')
                            }
                            openSide="top"
                            openOnMouseOver
                            miscStyles={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                            }}
                            tooltipMiscStyles={{
                              backgroundColor: '#505050',
                              type: -2,
                              justifyContent: 'center',
                              ...(isVerifiedUser
                                ? { fontWeight: '700', maxHeight: '12rem', }
                                : { minWidth: '35rem', }),
                            }}
                          >
                            {authorRate > 60 ? (
                              <FelaComponent
                                style={{
                                  marginInlineStart: '1rem',
                                  display: 'flex',
                                  marginBottom: '0.5rem',
                                }}
                                as="span"
                              >
                                {!isVerifiedUser ? (
                                  <IconStar
                                    leftColor={
                                      isGoldUser ? 'sales' : [ 'neutral', '-4', ]
                                    }
                                    rightColor={
                                      isGoldUser ? 'sales' : [ 'neutral', '-4', ]
                                    }
                                  />
                                ) : (
                                  <IconCheck color="primary" />
                                )}
                              </FelaComponent>
                            ) : null}
                          </Tooltip>
                        ) : null}
                        <FelaComponent style={publishingDateStyle} as="span">
                          {publishingDateForDisplay}
                        </FelaComponent>

                        {isSubComment ? (
                          <FelaComponent
                            style={subCommentAuthorStyle}
                            as="span"
                          >
                            <IconArrow
                              color={[ 'comments', 'subcommentAuthor', ]}
                              miscStyles={{
                                ...(direction === 'ltr'
                                  ? { transform: 'rotateY(180deg)', }
                                  : {}),
                              }}
                            />
                            {parentAuthor}
                          </FelaComponent>
                        ) : (
                          undefined
                        )}
                        {isEditorPick === true ? (
                          <StyledEditorPickTag>
                            |
                            {editorsPick}
                          </StyledEditorPickTag>
                        ) : (
                          undefined
                        )}
                        {this.isUsersChoice ? (
                          <StyledEditorPickTag>
                            |
                            {usersPick}
                          </StyledEditorPickTag>
                        ) : (
                          undefined
                        )}
                      </FelaComponent>
                      <FelaComponent
                        style={({ theme, }) => ({
                          extend: [
                            theme.mq(
                              { until: 's', },
                              { paddingBlockStart: '1rem', }
                            ),
                          ],
                        })}
                      >
                        <Like
                          iconColor={[ 'comments', 'likeIcon', ]}
                          commentId={commentId}
                          initVote={initVote}
                          isDisabled={!!this.state.userLike || isAnonymousHdc}
                          miscStyles={likeMiscStyles}
                          rate={commentPlusRate}
                          updateUserLike={() => {
                            this.setState({ userLike: 'plus', });
                          }}
                          isAnonymousIconColor={isAnonymousHdc}

                        />
                        <Like
                          iconColor={[ 'comments', 'likeIcon', ]}
                          commentId={commentId}
                          initVote={initVote}
                          isDisabled={!!this.state.userLike || isAnonymousHdc}
                          isDisLike
                          miscStyles={disLikeMiscStyles}
                          rate={commentMinusRate}
                          updateUserLike={() => {
                            this.setState({ userLike: 'minus', });
                          }}
                          isAnonymousIconColor={isAnonymousHdc}
                        />
                      </FelaComponent>
                      <div
                        // eslint-disable-next-line
                        ref={commentTextEl => {
                          this.commentTextEl = commentTextEl;
                        }}
                      >
                        <StyledCommentText>
                          {decodeCommonHTMLEntities(title)}
                        </StyledCommentText>
                        <StyledCommentText
                          dangerouslySetInnerHTML={this.generateCommentMarkup()}
                          fade={this.state.fadeText}
                        />
                        {this.state.fadeText ? (
                          <Fade isHighlighted={isHighlighted} />
                        ) : null}
                      </div>
                      <FelaComponent style={commentFooterStyle}>
                        {disableReply ? null : (
                          <Button
                            isFlat={!isWebView}
                            variant={commentsStyle.sendButtonVariant}
                            miscStyles={{
                              backgroundColor: 'transparent',
                              marginInlineEnd: '2rem',
                              type: [ { value: isWebView ? -1 : 0, }, ],
                              ':focus': {
                                backgroundColor: theme.color('primary', 'base'),
                                color: theme.color('bg', 'base'),
                              },
                            }}
                            boxModel={{ hp: 1, vp: 1, }}
                            onClick={this.handleReplyClick}
                          >
                            {replyBtnTxt}
                          </Button>
                        )}
                        {this.state.fadeText ? (
                          <Button
                            isFlat={!isWebView}
                            variant={commentsStyle.sendButtonVariant}
                            miscStyles={{
                              backgroundColor: 'transparent',
                              type: [ { value: isWebView ? -1 : 0, }, ],
                            }}
                            boxModel={{ hp: 1, vp: 1, }}
                            onClick={() => {
                              this.setState({ fadeText: false, });
                            }}
                          >
                            {readMoreBtnTxt}
                          </Button>
                        ) : null}

                        {!this.state.fadeText && !isAnonymousHdc ? (
                          <Button
                            isFlat
                            variant="negative"
                            boxModel={{ hp: 0, vp: 1, }}
                            miscStyles={{
                              color: theme.color('comments', 'report'),
                              backgroundColor: abuseReportPending
                                ? theme.color('comments', 'report')
                                : 'transparent',
                              minWidth: '11rem',
                              type: [ { value: -2, }, ],
                              marginInlineStart: 'auto',
                              paddingLeft: '1rem',
                              opacity: '1',
                            }}
                            isDisabled={abuseReported || reportAbuseDisabled}
                            isBusy={abuseReportPending}
                            onClick={() => {
                              reportAbuse(commentId).then(
                                ({ reportSuccess, }) => {
                                  this.setState({
                                    abuseReportState: reportSuccess
                                      ? reportStates.reported
                                      : reportStates.failed,
                                  });
                                }
                              );
                              this.setState({
                                abuseReportState: reportStates.pending,
                              });
                            }}
                          >
                            {abuseReported
                              ? reportReceivedTxt
                              : abuseReportPending
                                ? ''
                                : abuseReportFailed
                                  ? reportErrorTxt
                                  : reportAbuseBtnTxt}
                          </Button>
                        ) : null}
                      </FelaComponent>
                      {(!isWebView || isPageLoaded)
                      && abuseReportState !== reportStates.initial ? (
                        <FelaComponent
                          style={{
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Recaptcha
                            sitekey="6LcC3usUAAAAAByOPVWv3pn9KXAwstot5vYjk1Gb"
                            onResolved={() => console.log('captcha resolved')}
                            badge="inline"
                          />
                        </FelaComponent>
                        ) : null}
                    </FelaComponent>
                    {' '}
                    {subComments ? (
                      <FelaComponent
                        style={{
                          gridColumn: '2',
                        }}
                      >
                        <CommentList
                          comments={subComments}
                          parentAuthor={author}
                          subSingleCommentId={subSingleCommentId}
                          isSubComment
                          disableReply={disableReply}
                          initVote={initVote}
                          commentsPlusRate={commentsPlusRate}
                          initNewComment={initNewComment}
                          commentsMinusRate={commentsMinusRate}
                          reportAbuse={reportAbuse}
                          reportAbuseDisabled={reportAbuseDisabled}
                          userType={userType}
                          openParentReplyForm={() => this.setState({ displayReplyForm: true, })
                          }
                          enableNickname={enableNickname}
                        />
                      </FelaComponent>
                    ) : null}
                  </FelaComponent>
                </div>
              )}
            </FelaComponent>
            {this.state.displayReplyForm ? (
              <CommentForm
                parentCommentId={commentId}
                initNewComment={initNewComment}
                signUpNotification={signUpNotification}
                userType={userType}
                closeReplyForm={() => this.setState({ displayReplyForm: false, })
                }
                enableNickname={enableNickname}
                fwRef={this.commentFormRef}
              />
            ) : null}
          </Section>
        )}
      </FelaComponent>
    );
  }
}

export default Comment;
