
/* global window */
import React, { useCallback, useState, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import Recaptcha from 'react-google-invisible-recaptcha';
import { borderBottom, } from '@haaretz/htz-css-tools';
import { useApolloClient, } from 'react-apollo';

import { useEventTracker, } from '../../utils/EventTracker';
import CommentList from './CommentList'; // eslint-disable-line import/no-named-as-default
import Select from '../Select/Select'; // eslint-disable-line import/no-named-as-default
import Button from '../Button/Button'; // eslint-disable-line import/no-named-as-default
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import { useOrderComments, } from './CommentOrderProvider';

const SelectStyle = {
  width: '28rem',
  marginInlineStart: '2.3rem',
};

CommentsSection.propTypes = {
  /**
   * An Array of comment objects
   */
  comments: PropTypes.arrayOf(PropTypes.object),
  /**
   * An Array of single comment with replayed sub-comment objects
   */
  singleComment: PropTypes.arrayOf(PropTypes.object),
  /**
   * An Object holding the Minus Rates of all the comments in the `CommentsElement`.
   * The Object has an id key for each comment that has at least one Minus vote.
   * The corresponding value is the total number of Minus votes the comment has.
   */
  commentsMinusRate: PropTypes.shape({
    id: PropTypes.number,
  }),
  /**
   * An Object holding the Plus Rates of all the comments in the `CommentsElement`.
   * The Object has an id key for each comment that has at least one Plus vote.
   * The corresponding value is the rate of Plus votes the comment has.
   */
  commentsPlusRate: PropTypes.shape({
    id: PropTypes.number,
  }),
  /**
   * A callback passed on to the reply `<CommentForm />`
   * @param {String} commentAuthor
   *   The new comment author
   * @param {String} commentTextHtml
   *   The new comment text innerHTML
   * @param {String} parentCommentId
   *   The parent CommentId. Defaults to '0' if there is no `parentCommentId`
   */
  initNewComment: PropTypes.func.isRequired,
  /**
   * A callaback that initiates a vote, `<Likes />` sends up the commentId and the rate ("plus"/"minus")
   */
  initVote: PropTypes.func.isRequired,
  /** A callback that get's called when clicking load more button */
  loadMoreComments: PropTypes.func.isRequired,
  /**
   * A CallBack that gets called `onReCaptchaResolve`,
   * `onSubmitCaptcha` which executes the captcha gets called when the
   * reportAbuse event from `<CommentList />` gets called, it saves the commentId in
   * `<CommentSection/>` state to later use `onCaptchaResolve`
   * @param {String} commentId - commentId
   * @param {String} captchaKey - captchaKey
   */
  reportAbuse: PropTypes.func.isRequired,
  /**
   * A callback that gets the called when submitting the sign up to notification form in `<CommentSent />`
   * @param {String} - notificationEmail - The email the user entered
   */
  signUpNotification: PropTypes.func.isRequired,
  /** used to calculate comment numbers */
  totalHits: PropTypes.number.isRequired,
  userType: PropTypes.string,
  /**
   * Disables the Reply button
   */
  disableReply: PropTypes.bool,
  /**
   * Enable / disable choosing nick-name
   */
  enableNickname: PropTypes.bool,
  commentPaginationCount: PropTypes.number.isRequired,
  initialMaxCommentsToRender: PropTypes.number.isRequired,
  subSingleCommentId: PropTypes.string,
  bannersInterval: PropTypes.number,
  banners: PropTypes.arrayOf(PropTypes.object),
};

CommentsSection.defaultProps = {
  comments: [],
  singleComment: [],
  commentsPlusRate: {},
  commentsMinusRate: {},
  userType: null,
  disableReply: false,
  enableNickname: true,
  subSingleCommentId: null,
  bannersInterval: null,
  banners: [],
};


function CommentsSection({
  initNewComment,
  signUpNotification,
  comments,
  commentsPlusRate,
  commentsMinusRate,
  singleComment,
  initVote,
  totalHits,
  reportAbuse,
  loadMoreComments,
  userType,
  disableReply,
  enableNickname,
  commentPaginationCount,
  initialMaxCommentsToRender,
  subSingleCommentId,
  bannersInterval,
  banners,
}) {
  const { theme, css, } = useFela();
  const { biAction, biImpression, } = useEventTracker();
  const client = useApolloClient();

  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.06, });
  React.useEffect(() => {
    Array.isArray(comments) && comments.length > 0 && inView && biImpression && biImpression({
      feature: 'Scroll',
      featureType: 'Content',
      campaignName: 'Talkbacks',
    });
  }, [ biImpression, comments, inView, ]);
  const {
    commentsStyle,
    commentsSectionI18n: {
      buttons: { loadMoreCommentsBtnText, },
      texts: { chooseSortMethodText, },
      selectItems: {
        dateDescendingItemTxt,
        dateAscendingItemTxt,
        commentRatingItemTxt,
        editorsPickItemTxt,
      },
    },
  } = theme;
  const { sortMethod, setSortMethod, } = useOrderComments();
  const [ isSingleCommentView, setIsSingleCommentView, ] = React.useState(!!singleComment);
  const [ maxCommentsToRender, setMaxCommentsToRender, ] = React.useState(
    isSingleCommentView ? 1 : initialMaxCommentsToRender
  );
  const topInsertedCommentRef = React.useRef(null);
  const insertedCommentIdx = maxCommentsToRender - commentPaginationCount;
  const createCommentWrapperRef = React.useCallback(idx => el => {
    if (insertedCommentIdx < initialMaxCommentsToRender) {
      topInsertedCommentRef.current = null;
    }
    else if (idx === insertedCommentIdx) {
      topInsertedCommentRef.current = el;
    }
  }, [ initialMaxCommentsToRender, insertedCommentIdx, ]);

  React.useEffect(() => {
    if (topInsertedCommentRef.current) {
      topInsertedCommentRef.current.tabIndex = '-1';
      topInsertedCommentRef.current.focus();
    }
  }, [ maxCommentsToRender, ]);

  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);

  if (singleComment && typeof document === 'undefined') {
    client.writeData({
      data: {
        isReadyToRenderExternals: false,
      },
    });
  }

  const onLoadElement = useCallback(e => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  const [ isAboutToReport, setIsAboutToReport, ] = React.useState(false);
  const recaptcha = React.useRef(null);
  const reportCommentId = React.useRef(null);
  const reportCommentResolve = React.useRef(null);

  const onReCaptchaResolve = () => {
    const resolve = reportCommentResolve.current || (() => {});
    const captchaKey = recaptcha.current.getResponse();
    reportAbuse(reportCommentId.current, captchaKey)
      .then(resolve);

    setIsAboutToReport(false);
    reportCommentId.current = null;
    reportCommentResolve.current = null;
    recaptcha.current.reset();
  };

  const onReCaptchaError = () => {
    const resolve = reportCommentResolve.current;

    if (resolve == null) {
      return;
    }

    setIsAboutToReport(false);
    reportCommentId.current = null;
    reportCommentResolve.current = null;
    recaptcha.current.reset();

    resolve({ reportSuccess: false, });
  };

  const onSubmitCaptcha = commentId => {
    if (reportCommentId.current != null) {
      console.warn('Submit captcha activated while still handling a previous captcha');
      return Promise.resolve({ reportSuccess: false, });
    }

    setIsAboutToReport(true);
    reportCommentId.current = commentId;
    const reportCommentPromise = new Promise(resolve => {
      reportCommentResolve.current = resolve;
    });
    recaptcha.current.execute();

    return reportCommentPromise;
  };

  const getCommentNetRate = commentId => {
    const commentPlusRate = Object.prototype.hasOwnProperty.call(commentsPlusRate, commentId)
      ? commentsPlusRate[commentId]
      : 0;
    const commentMinusRate = Object.prototype.hasOwnProperty.call(commentsMinusRate, commentId)
      ? commentsMinusRate[commentId]
      : 0;

    return commentPlusRate - commentMinusRate;
  };

  const sortCommentsByMethod = orderName => {
    if (orderName === 'firstToLast') {
      return (a, b) => a.publishingDateSortable - b.publishingDateSortable;
    }
    if (orderName === 'rate') {
      return (a, b) => getCommentNetRate(b.commentId) - getCommentNetRate(a.commentId);
    }
    if (orderName === 'editorsPick') {
      return (a, b) => (a.isEditorPick === b.isEditorPick ? 0 : a.isEditorPick === true ? -1 : 1);
    }
    return (a, b) => b.publishingDateSortable - a.publishingDateSortable;
  };

  const getCommentsWithNumber = rawComments => {
    const sortedComments = [ ...rawComments, ];// .sort(sortCommentsByMethod(sortMethod?.value || 'lastToFirst'));
    const sortedCommentsWithNumbers = sortedComments.map((comment, idx) => ({
      ...comment,
      number: sortMethod.value === 'lastToFirst' ? totalHits - idx : idx + 1,
    }));

    return sortedCommentsWithNumbers;
  };
  const sortComments = rawComments => {
    const commentsWithNumber = getCommentsWithNumber(rawComments || []);
    return commentsWithNumber
      .concat()
      .sort(sortCommentsByMethod(sortMethod.value))
      .slice(0, maxCommentsToRender);
  };

  const loadComments = () => {
    if (totalHits > comments.length) {
      loadMoreComments();
    }
    setIsSingleCommentView(false);
  };

  return (
    <div
      className={css({
        fontFamily: theme.fontStacks[theme.framedFont],
        extend: [
          theme.mq({ until: 's', }, { paddingBottom: '3rem', }),
          theme.mq({ from: 's', until: 'l', }, { paddingBottom: '7rem', }),
          theme.mq({ from: 'l', until: 'xl', }, { paddingBottom: '9rem', }),
          theme.mq({ from: 'xl', }, { paddingBottom: '1rem', }),
        ],
      })}
      ref={ref}
    >
      {!singleComment ? (
        <div
          className={css({
            width: '100%',
            marginBottom: '5rem',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'flex-start',
          })}
        >
          <div className={css({
            marginTop: 'calc(1rem + 2px)',
            zIndex: 1,
          })}
          >
            {chooseSortMethodText}
          </div>
          <Select
            shouldBoldSelectedItem
            variant={commentsStyle.textInputVariant}
            onChange={selectedItem => {
              loadComments();
              setSortMethod(selectedItem);

              const selectedItemType = selectedItem?.value
                ? selectedItem?.value === 'firstToLast'
                  ? 'Date Ascending'
                  : selectedItem?.value === 'lastToFirst'
                    ? 'Date Descending'
                    : selectedItem?.value === 'rate'
                      ? 'Comment Rating'
                      : selectedItem?.value === 'editorsPick'
                        ? 'Editors Pick'
                        : selectedItem?.value
                : null;

              biAction({
                actionCode: 104,
                feature: 'Sort talkbacks',
                featureType: 'Content',
                campaignName: selectedItemType,
              });
            }}
            controlledSelectedItem={sortMethod}
            items={[
              { value: 'lastToFirst', display: dateDescendingItemTxt, },
              { value: 'firstToLast', display: dateAscendingItemTxt, },
              { value: 'rate', display: commentRatingItemTxt, },
              { value: 'editorsPick', display: editorsPickItemTxt, },
            ]}
            miscStyles={SelectStyle}
            buttonMiscStyles={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              backgroundColor: theme.color('bg', 'base'),
              ':focus': {
                backgroundColor: theme.color('bg', 'base'),
              }, }}
          />
        </div>
      )
        : null}
      {Array.isArray(singleComment) ? (
        <div className={css({
          extend: [
            borderBottom(
              '1px',
              2,
              'solid',
              theme.color('comments', 'border')
            ),
          ],
        })}
        >
          <CommentList
            createCommentWrapperRef={createCommentWrapperRef}
            comments={sortComments(singleComment)}
            commentsPlusRate={commentsPlusRate}
            commentsMinusRate={commentsMinusRate}
            initVote={initVote}
            reportAbuse={commentId => onSubmitCaptcha(commentId)}
            reportAbuseDisabled={isAboutToReport}
            initNewComment={initNewComment}
            signUpNotification={signUpNotification}
            userType={userType}
            disableReply={disableReply}
            enableNickname={enableNickname}
            isWebView={isWebView}
            isPageLoaded={isLoaded}
            isSingleComment={singleComment}
            subSingleCommentId={subSingleCommentId}
            bannersInterval={bannersInterval}
            banners={banners}
          />
        </div>
      ) : null}
      {!singleComment || !isSingleCommentView ? (
        <CommentList
          createCommentWrapperRef={createCommentWrapperRef}
          comments={sortComments(comments)}
          commentsPlusRate={commentsPlusRate}
          commentsMinusRate={commentsMinusRate}
          initVote={initVote}
          reportAbuse={commentId => onSubmitCaptcha(commentId)}
          reportAbuseDisabled={isAboutToReport}
          initNewComment={initNewComment}
          signUpNotification={signUpNotification}
          userType={userType}
          disableReply={disableReply}
          enableNickname={enableNickname}
          isWebView={isWebView}
          isPageLoaded={isLoaded}
          bannersInterval={bannersInterval}
          banners={banners}
        />
      ) : null}
      {totalHits > maxCommentsToRender ? (
        <div
          className={css({
            marginTop: '3rem',
            textAlign: 'center',
          })}
        >
          <Button
            attrs={{ 'data-test': 'loadComments', }}
            variant={commentsStyle.sendButtonVariant}
            onClick={() => {
              loadComments();
              setMaxCommentsToRender(prevMax => prevMax + commentPaginationCount);
            }}
            miscStyles={{
              backgroundColor: theme.color('bg', 'base'),
            }}
          >
            {loadMoreCommentsBtnText}
          </Button>
        </div>
      ) : null}
      {!isWebView || isLoaded
        ? (
          <Recaptcha
            ref={recaptcha}
          // todo: 'should site key be prop ? or from theme ?
            sitekey="6LcC3usUAAAAAByOPVWv3pn9KXAwstot5vYjk1Gb"
            onResolved={onReCaptchaResolve}
            onError={onReCaptchaError}
            style={{ display: 'none', }}
            badge="inline"
          />
        ) : null
      }
    </div>
  );
}

export default CommentsSection;
